import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import color from '../color-css/color';
import FormStepIndicator from '../../components/FormStep'
import parse from 'html-react-parser';
import './index.css'

export type Props = {
  isFormStepVisible: boolean
  fcpContent: object
  heading?: string | boolean
  title?: string
}

const FlexContainer = styled.div`
  display: flex;
  max-width: 77.5rem;
  margin: auto;
  flex-wrap: wrap;
`

type FormSuccessProps = {
  hasPageTitle?: string
}

const FormSuccess = styled.div<FormSuccessProps>`
  font-family: museo-sans, sans-serif !important;
  display: flex;
  flex-direction: column;
  margin-top: ${props => props?.hasPageTitle ? '10.5rem' : '0'};
  margin-bottom: 6.25rem;
  @media only screen and (max-width: 1299px) {
    margin-left: 0.938rem;
    margin-right: 0.938rem;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 5.75rem;
    margin-right: 5.75rem;
  }
  @media only screen and (max-width: 870px) {
    width: 40.9375rem;
    margin-top: ${props => props?.hasPageTitle ? '3.935rem' : '0'};
    margin-bottom: 3.125rem;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (max-width: 690px) {
    width: auto;
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
`

const HeaderWrapper = styled.div`
  text-align: center;
  @media only screen and (max-width: 768px) {
    text-align: left;    
  }
`

const FormSuccessHeader = styled.p`
  font-family: Intro, sans-serif !important;
  font-size: 1.125rem;
  color: ${color.blue_darker};
  margin-bottom: 0.9375rem;
  @media only screen and (max-width: 870px) {
    font-size: 0.9375rem;
    line-height: 1.375rem;
    margin-bottom: 0.9375rem;
  }
`

const FormSuccessTitle = styled.h1`
  font-size: 1.875rem;
  font-weight: 900;
  line-height: 2.5rem;
  margin: 0;
  color: ${color.blue_17_percent};
  
  @media only screen and (max-width: 870px) {
    margin-bottom: 0;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`

const FormSuccessContent = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  grid-column-gap: 0.625rem;
  grid-row-gap: 3.5rem;
  margin-top: 4.25rem;
  @media only screen and (min-width: 1300px) { 
    grid-row-gap: 7.5rem;
  }
  @media only screen and (max-width: 870px) {
    display: flex;
    flex-direction: column;
    grid-row-gap: 0;
    margin-top: 3.9375rem;
  }
  @media only screen and (max-width: 690px) {
    margin-top: 1.9375rem;
  }
`

interface OneColStyle {
  singleColInRow?: boolean,
  fullWidthColumn?: boolean
}

interface FlexRowStyle {
  paragraphSpacing?: boolean,
  lastRow?: boolean
}

const FlexRow = styled.div<FlexRowStyle>`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  justify-content: space-between;
  margin-bottom: 0;

  @media only screen and (min-width: 871px) {
    order: unset !important;
    margin-bottom: ${props => props.paragraphSpacing ? '3.125rem' : props.lastRow ? '0' : '7.5rem'};
  }
`

const FormSuccessParaWrapper = styled.div<OneColStyle>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: ${props => props.singleColInRow ? '100%' : '47.5%'};

  @media only screen and (min-width: 871px) {
    order: unset !important;
  }
  @media only screen and (max-width: 870px) {
    flex-basis: 100%;
  }

  img {
    border-radius: 8px;
    max-width: 100%;
    margin: 0px 0px 1.45rem;
    padding: 0px;
    border-style: none;
    max-height: 25rem;
  }
`

const FormSuccessPara = styled.div<OneColStyle>`
  font-size: 1rem;
  line-height: 1.75rem;
  @media only screen and (min-width: 992px) {
    max-width: ${props => props.fullWidthColumn ? '55.563rem' : '27.563rem'};
  }
  @media only screen and (max-width: 1299px) {
    margin-bottom: ${props => props.fullWidthColumn ? 0 : '3.125rem'};
    font-size: 0.9375rem;
    line-height: 1.375rem;
  }
  @media only screen and (max-width: 870px) {
    margin-bottom: 0;
  }
`

interface ContainerFormStep {
  isFormStepVisible: boolean
}

const ContainerFormStep = styled.div<ContainerFormStep>`
  display: ${props => props.isFormStepVisible ? 'block' : 'none'};
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: -10.5rem;
  align-items:'center';
  width: 52.3125rem;
  transform: translateY(-50%);
  @media screen and (max-width: 1024px) {
    top: -8.5rem;
  }
  @media screen and (max-width: 870px) {
    width: 10.8125rem;
    top: -3.9375rem;
  }
`

const FormStepIndicatorPositioningDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 5.9375rem;
  border-radius: 0.625rem;
  box-shadow: 0 1.25rem 3.125rem rgba(1,47,87,0.1);
  -ms-box-shadow: 0px 1.25rem 3.125rem rgba(1,47,87,0.1);
  box-sizing: border-box;
  background-color: ${color.white};
  @media screen and (max-width: 870px) {
    height: 2.8125rem;
    width: 10.8125rem;
    box-shadow: 0 1.25rem 3.125rem rgba(1,47,87,0.1);
    box-sizing: border-box;
  }
`

export const FormStepSuccessFormat: React.FC<Props> = (props: any) => {
  const { isFormStepVisible } = props

  return (
    <>
      <ContainerFormStep isFormStepVisible={isFormStepVisible}>
        <FormStepIndicatorPositioningDiv>
          <FormStepIndicator isFormComplete={true} />
        </FormStepIndicatorPositioningDiv>
      </ContainerFormStep>
      <FormSuccess hasPageTitle={props?.title}>
        <HeaderWrapper>
          {
            props?.heading && (
              <FormSuccessHeader>{props?.heading}</FormSuccessHeader>
            )
          }
          {props?.title && <FormSuccessTitle>{props.title}</FormSuccessTitle>}
        </HeaderWrapper>
        <FormSuccessContent>
          <FlexContainer>
            {
              props?.fcpContent?.map((row: any, index: number) => {
                const isLastRow = props.fcpContent.length - 1 === index
                if (row?.col1 && row?.col2) {
                  // If the second column in this row is a fullWidth column
                  // and the first is not. The second column's flex order should be first on mobile
                  const flexOrder = row.col1?.fullWidth && !row.col2?.fullWidth ? 2 : 0
                  return (
                    <FlexRow lastRow={isLastRow}>
                      <FormSuccessParaWrapper style={{ order: flexOrder }}>
                        <FormSuccessPara className='content-data-css' fullWidthColumn={row.col1?.fullWidth}>
                          {parse(`${row.col1.html}`)}
                        </FormSuccessPara>
                      </FormSuccessParaWrapper>
                      <FormSuccessParaWrapper style={{ order: 1 }}>
                        <FormSuccessPara className='content-data-css' fullWidthColumn={row.col2?.fullWidth}>
                          {parse(`${row.col2.html}`)}
                        </FormSuccessPara>
                      </FormSuccessParaWrapper>
                    </FlexRow>
                  )
                }
                if (row?.col1) {
                  return (
                    <FlexRow paragraphSpacing={true} lastRow={isLastRow}>
                      <FormSuccessParaWrapper singleColInRow={true} style={{ order: 0 }}>
                        <FormSuccessPara className='content-data-css' fullWidthColumn={row.col1?.fullWidth}>
                          {parse(`${row.col1.html}`)}
                        </FormSuccessPara>
                      </FormSuccessParaWrapper>
                    </FlexRow>
                  )
                }
                if (row?.col2) {
                  return (
                    <FlexRow paragraphSpacing={true} lastRow={isLastRow}>
                      <FormSuccessParaWrapper singleColInRow={true} style={{ order: 0 }}>
                        <FormSuccessPara className='content-data-css' fullWidthColumn={row.col2?.fullWidth}>
                          {parse(`${row.col2.html}`)}
                        </FormSuccessPara>
                      </FormSuccessParaWrapper>
                    </FlexRow>
                  )
                }
              })
            }
          </FlexContainer>
        </FormSuccessContent>
      </FormSuccess>
    </>
  );
}

export default FormStepSuccessFormat;
