import React from 'react'
import FormStepSuccess from '../components/FormStepSuccess/index'
import Layout from '../components/layout'
import branchSpecific from '../utils/branchSpecificCopy'
import SEO from '../components/seo'
import { useSelector } from 'react-redux'
import { RootState } from 'store/'
import {
  STATE_FROM_SUBURB
} from '../components/FormHighOrder/types/YourWorkField'

const FormSuccessPage = (props: any) => {

  const getBranch: string | undefined = useSelector((state: RootState) =>
    state.FormHighOrder.pageData[0].data[STATE_FROM_SUBURB].value)

  let branch = getBranch || props?.location?.state?.branch

  // Success page should redirect back to home path if the branch prop is unset (like it is via direct access to the page)
  if (typeof branch === 'undefined' && typeof window !== 'undefined') {
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.innerHTML = `window.location.href = '/'`
    document.body.appendChild(s)
  }

  return (
    <Layout
      title="Join the SDA"
      titleSpan="today"
      hasFooter={false}
    >
      <SEO
        noindex={true}
        lang={'en'}
        title={'SDA Union | Join Success'}
      />
      <FormStepSuccess
        heading={`WHAT'S NEXT`}
        title={`Your membership application is complete!`}
        fcpContent={branchSpecific(branch, 'joinSuccess')?.rows}
        isFormStepVisible={true}
      />
    </Layout>
  )
}

export default FormSuccessPage
